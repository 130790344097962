@import "~@/styles/variables/variables.scss";








































































.content {
	display: flex;
	flex-direction: column;
}
.content .total {
	margin-top: 10px;
	flex: 0 0 30px;
	.green {
		color: green;
	}
	.red {
		color: red;
	}
	.bold {
		font-weight: bold;
		font-size: 16px;
		margin: 0 5px;
	}
}
