@import "~@/styles/variables/variables.scss";
























































































































































































































































































@import '../runLayout.scss';
.content {
	flex: 1;
	height: 0;
	display: flex;
	.left {
		width: 300px;
		height: 100%;
		padding: 20px 12px;
		border-right: 1px solid #f1f1f1;
	}
	::v-deep .run-main {
		height: 100%;
		width: 0;
	}
}
